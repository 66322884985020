// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.next-video-popup-container {
    display: flex;
    flex-direction: row;
    height: 16rem;
    width: 40rem;
    animation: slide-fade-in 0.5s ease-in;

    @keyframes slide-fade-in {
        0% {
            opacity: 0;
            transform: translateX(calc(40rem + 2rem));
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .poster-container {
        flex: 1 1 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--overlay-color);

        .poster-image {
            flex: none;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }

        .placeholder-icon {
            flex: none;
            width: 80%;
            height: 50%;
            color: var(--primary-foreground-color);
        }
    }

    .info-container {
        flex: 1 1 70%;
        display: flex;
        flex-direction: column;

        .details-container {
            flex: auto;
            padding: 1.5rem 1.5rem;

            .name {
                flex: none;
                align-self: stretch;
                max-height: 2.4em;
                font-weight: 700;
                margin-bottom: 0.5rem;
                color: var(--primary-foreground-color);
            }

            .description {
                color: var(--primary-foreground-color);
                opacity: 0.5;
            }
        }

        .buttons-container {
            display: flex;
            flex-direction: row;

            .spacing {
                flex: 0 0 50%;
            }

            .button-container {
                flex: 0 0 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 3.5rem;

                &.play-button {
                    border-radius: 0 0 var(--border-radius) 0;
                    background-color: var(--secondary-accent-color);

                    .icon {
                        color: var(--primary-foreground-color);
                    }

                    .label {
                        color: var(--primary-foreground-color);
                    }

                    &:hover {
                        outline: var(--focus-outline-size) solid var(--secondary-accent-color);
                        background-color: transparent;
                    }
                }

                .icon {
                    flex: none;
                    width: 1.4rem;
                    height: 1.4rem;
                    margin-right: 1rem;
                    color: var(--primary-foreground-color);
                }

                .label {
                    flex: none;
                    max-height: 2.4em;
                    font-size: 1.1rem;
                    font-weight: 500;
                    color: var(--primary-foreground-color);
                }

                &:hover, &:focus {
                    background-color: var(--overlay-color);
                }
            }
        }
    }
}