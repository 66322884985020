// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

:import('~stremio/common/ModalDialog/styles.less') {
    label: label;
}

.addon-details-modal-container {
    .addon-details-container, .addon-details-message-container {
        width: 40rem;
        max-width: 100%;
        color: var(--primary-foreground-color);
    }

    .cancel-button {
        background-color: transparent;
        opacity: 0.3;

        &:hover {
            outline: var(--focus-outline-size) solid var(--primary-foreground-color);
            opacity: 1;
        }

        &:focus {
            outline-color: var(--primary-foreground-color);
        }

        .cancel-button-label {
            color: var(--primary-foreground-color);
        }
    }
}