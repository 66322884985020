// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/PlayIconCircleCentered/styles.less') {
    play-icon-circle-centered-background: background;
    play-icon-circle-centered-icon: icon;
}

.stream-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: var(--border-radius);

    &:hover, &:focus {
        background-color: var(--overlay-color);
    }

    &:hover, &:focus, &:global(.selected) {
        .play-icon {
            opacity: 1;

            .play-icon-circle-centered-background {
                fill: var(--secondary-accent-color);
            }

            .play-icon-circle-centered-icon {
                fill: var(--primary-foreground-color);
            }
        }
    }

    .thumbnail-container, .addon-name-container {
        flex: none;

        .thumbnail {
            display: block;
            height: 5rem;
            width: 8rem;
            object-fit: cover;
            object-position: center;
            opacity: 0.9;
        }

        .placeholder-icon {
            display: block;
            height: 5rem;
            width: 8rem;
            padding: 1rem;
            color: var(--primary-foreground-color);
            background-color: var(--overlay-color);
            opacity: 0.25;
        }

        .addon-name {
            width: 5rem;
            max-height: 3.6em;
            font-size: 1.1rem;
            text-align: center;
            color: var(--primary-foreground-color);
        }
    }

    .info-container {
        flex: 1;
        max-height: 3.6em;
        padding: 0 0.5rem 0 1.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: var(--primary-foreground-color);
        white-space: pre;
    }

    .play-icon {
        flex: none;
        width: 3.5rem;
        height: 5rem;
        opacity: 0;

        .play-icon-circle-centered-background {
            fill: none;
        }

        .play-icon-circle-centered-icon {
            fill: var(--primary-foreground-color);
        }
    }

    .progress-bar-container {
        flex: 0 0 100%;
        background-color: var(--primary-accent-color);

        .progress-bar {
            height: 0.5rem;
            background-color: var(--primary-foreground-color);
        }
    }
}

@media only screen and (max-width: @minimum) {
    .stream-container {
        .thumbnail-container, .addon-name-container {
            .addon-name {
                font-weight: 500;
            }
        }

        .play-icon {
            .play-icon-circle-centered-icon {
                fill: var(--primary-foreground-color);
            }
        }
    }
}
