// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.label-container {
    // IOS specific 
    // prevents showing the default context-menu when long pressing an anchor in safari.
    -webkit-touch-callout: none !important;

    position: relative;
    overflow: visible;

    .menu-container {
        position: absolute;
        z-index: 1;
        overflow: hidden;
        visibility: hidden;
        border-radius: var(--border-radius);
        background-color: var(--modal-background-color);
        box-shadow: 0 1.35rem 2.7rem @color-background-dark5-40,
            0 1.1rem 0.85rem @color-background-dark5-20;
        cursor: auto;

        &.menu-direction-top-left {
            top: initial;
            right: 0;
            bottom: 100%;
            left: initial;
            visibility: visible;
        }

        &.menu-direction-bottom-left {
            top: 100%;
            right: 0;
            bottom: initial;
            left: initial;
            visibility: visible;
        }

        &.menu-direction-top-right {
            top: initial;
            right: initial;
            bottom: 100%;
            left: 0;
            visibility: visible;
        }

        &.menu-direction-bottom-right {
            top: 100%;
            right: initial;
            bottom: initial;
            left: 0;
            visibility: visible;
        }
    }
}