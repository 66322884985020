// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.videos-list-container {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;

    .message-container {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        overflow-y: auto;

        .image {
            flex: none;
            width: 10rem;
            height: 10rem;
            max-width: 100%;
            margin-bottom: 1rem;
            object-fit: contain;
            object-position: center;
            opacity: 0.9;
        }

        .label {
            flex: none;
            font-size: 1.4rem;
            text-align: center;
            color: var(--primary-foreground-color);
        }
    }

    .notifications-checkbox {
        flex: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        height: 3rem;
        padding: 0 1.5rem;
        color: @color-surface-light5-90;
    }

    .seasons-bar {
        flex: none;
        align-self: stretch;
        margin: 0.5rem 1rem 1rem 1rem;
    }

    .search-bar {
        flex: none;
        align-self: stretch;
        margin: 0 1.5rem 1rem 1.5rem;

        &:first-child {
            margin-top: 1rem;
        }
    }

    .videos-container {
        flex: 1;
        align-self: stretch;
        padding: 0 1rem;
        overflow-y: auto;
    }
}

@media only screen and (max-width: @minimum) {
    .videos-list-container {
        overflow: visible;
    }
}