// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/Multiselect/styles.less') {
    multiselect-menu-container: menu-container;
    multiselect-label: label;
    multiselect-icon: icon;
}

.seasons-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    overflow: visible;

    .prev-season-button, .next-season-button {
        flex: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 6.5rem;
        height: 3rem;
        border-radius: 3rem;
        padding: 0.5rem;

        &:hover, &:focus {
            background-color: var(--overlay-color);
        }

        &:focus {
            background-color: var(--primary-foreground-color);
        }

        &>:first-child {
            margin-right: 0.5rem;
        }

        .label {
            flex: 1;
            max-height: 1.2em;
            font-weight: 500;
            text-align: center;
            color: var(--primary-foreground-color);
        }

        .icon {
            flex: none;
            width: 1.5rem;
            height: 1.5rem;
            color: var(--primary-foreground-color);
        }
    }

    .seasons-popup-label-container {
        flex: 0 1 auto;
        background: none;

        &:hover, &:focus, &:global(.active) {
            background-color: var(--overlay-color);
        }

        &>.multiselect-label {
            color: var(--primary-foreground-color);
        }

        &>.multiselect-icon {
            color: var(--primary-foreground-color);
        }

        .multiselect-menu-container {
            max-height: calc(3.2rem * 7);
            overflow: auto;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .seasons-bar-container {
        height: 6rem;

        .seasons-popup-label-container {
            .multiselect-menu-container {
                max-height: calc(3.2rem * 3);
            }
        }
    }
}