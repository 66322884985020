// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.search-bar-container {
    --search-bar-size: 3.25rem;
    display: flex;
    flex-direction: row;
    height: var(--search-bar-size);
    border-radius: var(--search-bar-size);
    background-color: var(--overlay-color);

    .search-input {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0.5rem 0 2rem;
        font-weight: 500;
        color: var(--primary-foreground-color);
        cursor: text;

        &::placeholder, .placeholder-label {
            max-height: 1.2em;
            opacity: 1;
            color: var(--primary-foreground-color);
            opacity: 0.6;
        }
    }

    .submit-button-container {
        flex: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: var(--search-bar-size);
        padding: 0 1.5rem;

        .icon {
            flex: none;
            width: 1.7rem;
            height: 1.7rem;
            color: var(--primary-foreground-color);
            opacity: 0.6;
        }
    }
}